//disable eslint for this file
/* eslint-disable */
import { useEffect, useRef } from 'react';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { useChatStore } from '../store/chatStore';
import { clearAuthentication, getAuthenticationStringAttachment } from 'utils/AuthenticationHelpers';
import { v4 as uuidv4 } from 'uuid';
import { AIView, ChatResponse } from 'models/AIChatTypes';
import { t } from 'i18next';

export interface ModerationResult {
  flagged: boolean;
  categories: { [key: string]: boolean };
  categoryScores: { [key: string]: number };
  categoryAppliedInputTypes: { [key: string]: string[] };
}

function generateRapport(result: ModerationResult): string {
  const df = new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  let title = t('moderation.title');

  if (!result.flagged) {
    // this should never happen, backend does this aswell!
    return `${title}${t(t('moderation.passed'))}`;
  }

  let builder = `${title}${t('moderation.warning')}`;

  for (const category in result.categories) {
    if (result.categories[category]) {
      const score = result.categoryScores[category];
      const formattedScore = score != null ? df.format(score * 100) : 'N/A';

      builder += `### ${formatCategoryName(category)}\n`;
      builder += `- ${t('moderation.confidence')}: ${formattedScore}%\n`;

      const inputTypes = result.categoryAppliedInputTypes[category];
      if (inputTypes && inputTypes.length > 0) {
        builder += `- ${t('moderation.detected')}: ${inputTypes.join(', ')}\n`;
      }
      builder += '\n';
    }
  }

  return builder;
}

function formatCategoryName(category: string): string {
  const categoryMapping: { [key: string]: string } = {
    harassment: 'harassment',
    'harassment/threatening': 'harassmentThreatening',
    sexual: 'sexual',
    hate: 'hate',
    'hate/threatening': 'hateThreatening',
    illicit: 'illicit',
    'illicit/violent': 'illicitViolent',
    'self-harm/intent': 'self-harmIntent',
    'self-harm/instructions': 'self-harmInstructions',
    'self-harm': 'self-harm',
    'sexual/minors': 'sexualMinors',
    violence: 'violence',
    'violence/graphic': 'violenceGraphic',
  };

  let mapped = categoryMapping[category];

  if (mapped && mapped !== undefined) {
    const key = `moderation.categories.${mapped}`;

    mapped = t(key);
  } else {
    mapped = category;
  }

  const replaced = mapped.replace(/_/g, ' ').replace(/\//g, ' ').trim();
  return replaced.charAt(0).toUpperCase() + replaced.substring(1);
}

function addErrorAsResponse(error: ModerationResult): ChatResponse {
  return {
    id: `websocket-error-id-${uuidv4()}`,
    choices: [
      {
        delta: {
          content: generateRapport(error),
        },
      },
    ],
  };
}

export const useWebSocket = (url: string, clientUUID: string) => {
  const {
    setConnectionStatus,
    addResponse,
    addImageResponse,
    getViewLatestQuestionId,
    setCancelViewPrompt,
    setQuestionActiveStatus,
    markLastQuestionProcessed,
    setPrompt,
    cancelQuestion,
    activeView,
    messages,
    generateImageMessage,
    cancelViewPrompt,
    prompt,
    imageResponseBuffer,
    currentActiveView,
    setLastImageMessageStatus,
  } = useChatStore();
  const clientRef = useRef<Client | null>(null);

  useEffect(() => {
    clientRef.current = new Client({
      webSocketFactory: () => new SockJS(url),
      reconnectDelay: 5000,
      debug: (str) => {
        console.log(str);
      },
      onConnect: () => {
        setConnectionStatus('connected');
        clientRef.current?.subscribe('/user/queue/reply', (message) => {
          const activeView = currentActiveView();
          try {
            if (message.body) {
              if (message.body.includes('data: [DONE]')) {
                const data = message.body.split('!;!');
                if (data.length > 2) {
                  markLastQuestionProcessed(data[2]);
                  setQuestionActiveStatus(data[2], false);
                } else {
                  markLastQuestionProcessed(activeView);
                  setQuestionActiveStatus(activeView, false);
                }
                setPrompt(null);
                console.log('FINISHED PROCESSING YOUR PROMPT');

                return;
              }
              const splitMessage = message.body.split('!;!');
              const questionid = splitMessage[0];
              console.log('message: ', message.body);

              console.log('Question ID: ', questionid);
              if (splitMessage.length < 2 || splitMessage[1].length < 1) {
                console.log('No message body');
                return;
              } else {
                addResponse(splitMessage[2], splitMessage[1].substring(6));
                console.log('Message body: ', splitMessage[1].substring(6));
              }
            }
          } catch (error) {
            console.error('Error parsing message body: ' + error);
          }
        });

        clientRef.current?.subscribe('/user/queue/reply/image', (message) => {
          // Append incoming message to the buffer
          imageResponseBuffer.appendMessage(message.body);

          // Try to parse the buffered message
          const data = imageResponseBuffer.tryParse();

          // Check if data is not null (successfully parsed)
          if (data) {
            addImageResponse('generateImage', JSON.stringify(data));
          }
        });

        clientRef.current?.subscribe('/user/queue/moderation/error', (error) => {
          const activeView = currentActiveView();
          const message = JSON.parse(error.body);

          let errorMsgObject = addErrorAsResponse(message);

          addResponse(activeView, JSON.stringify(errorMsgObject)); // actually add the response to the chat

          if (activeView === AIView.GENERATE_IMAGE) setLastImageMessageStatus(false);

          cancelQuestion(activeView); // internally sets values and marks last question in the view as complete
          setCancelViewPrompt(null); // no clue why but its done in other places where we cancel questions so just to be sure we dont break the state we do it here
          setQuestionActiveStatus(activeView, false); // again set and also notify the listeners that the last question is complete in the view
        });

        clientRef.current?.subscribe('/user/queue/error', (error) => {
          const message = error.body;

          console.error(message);
        });
      },
      onStompError: (frame) => {
        if (frame.headers['message'].includes('Invalid authString')) {
          clearAuthentication();
          document.location.reload();
        }

        setConnectionStatus('error');
        console.error('Broker reported error: ' + frame.headers['message']);
        console.error('Additional details: ' + frame.body);
      },
      onWebSocketClose: (event) => {
        setConnectionStatus('closed');
        console.error('WebSocket connection closed: ', event);
      },
    });

    clientRef.current.connectHeaders = {
      Authorization: `Bearer ${getAuthenticationStringAttachment()}`,
      ClientUUID: clientUUID,
    };
    clientRef.current.activate();

    return () => {
      setQuestionActiveStatus(activeView, false);
      //setCancelViewPrompt(activeView);
      clientRef.current?.deactivate();
    };
  }, [url, setConnectionStatus]);

  useEffect(() => {
    if (prompt != null && clientRef.current?.connected) {
      console.log('PROMPT: ', prompt);

      clientRef.current.publish({
        destination: '/app/chat',
        headers: { authString: prompt.authString, ClientUUID: clientUUID },
        body: JSON.stringify(prompt),
      });
    }
  }, [prompt]);

  useEffect(() => {
    if (generateImageMessage != null && clientRef.current?.connected) {
      console.log('GENERATE IMAGE REQUEST: ', generateImageMessage);

      clientRef.current.publish({
        destination: '/app/image',
        headers: { authString: generateImageMessage.authString, ClientUUID: clientUUID },
        body: JSON.stringify(generateImageMessage),
      });
    }
  }, [generateImageMessage]);

  useEffect(() => {
    const latestQuestionId = getViewLatestQuestionId(activeView);
    if (cancelViewPrompt != null && clientRef.current?.connected && latestQuestionId) {
      console.log('cancelViewPrompt: ', cancelViewPrompt);
      clientRef.current.publish({
        destination: '/app/cancel',
        headers: {
          questionId: latestQuestionId,
          ClientUUID: clientUUID,
          authString: getAuthenticationStringAttachment() || '',
        },
        body: '',
      });
      setCancelViewPrompt(null);
    }
  }, [cancelViewPrompt]);
};
