import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useChatStore } from 'store/chatStore';
import './Form.scss';
// eslint-disable-next-line
import { GenerateImageMessageInner } from 'models/AIChatTypes';
import { Slider } from '@mui/material';
import { ModelSelector, ModelOption } from '../ModelSelector';

interface GenerateImageFormProps {
  models: ModelOption[];
}

interface FormErrors {
  size: boolean;
  prompt: boolean;
}

const v2sizes = ['256x256', '512x512', '1024x1024'];
const v3sizes = ['1024x1024', '1792x1024', '1024x1792'];

export const GenerateImageForm: React.FC<GenerateImageFormProps> = ({ models }) => {
  const [errors, setErrors] = useState<FormErrors>({ size: false, prompt: false });
  const [selectedModel, setSelectedModel] = useState(models[0]);
  const [formData, setFormData] = useState<GenerateImageMessageInner>({
    size: '256x256',
    prompt: '',
    model: selectedModel.model,
    response_format: 'b64_json',
    style: selectedModel.model === 'dall-e-3' ? 'natural' : undefined,
    quality: selectedModel.model === 'dall-e-3' ? 'standard' : undefined,
  });
  const { t } = useTranslation();

  const { activeView, questionActiveStatus, setGenerateImageMessage, addImageMessage } = useChatStore();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: validateField(name as keyof GenerateImageMessageInner, value) });
  };

  const handleDropdownChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    setSelectedModel(models[0]);
    const defaultSize = models[0].model === 'dall-e-3' ? v3sizes[0] : v2sizes[0];
    setFormData({
      size: defaultSize,
      prompt: '',
      model: models[0].model,
      response_format: 'b64_json',
      style: models[0].model === 'dall-e-3' ? 'natural' : undefined,
      quality: models[0].model === 'dall-e-3' ? 'standard' : undefined,
    });
  }, [models]);

  useEffect(() => {
    const defaultSize = selectedModel.model === 'dall-e-3' ? v3sizes[0] : v2sizes[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      model: selectedModel.model,
      size: defaultSize,
      style: selectedModel.model === 'dall-e-3' ? 'natural' : undefined,
      quality: selectedModel.model === 'dall-e-3' ? 'standard' : undefined,
    }));
    // eslint-disable-next-line
  }, [selectedModel]);

  const validateField = (name: string, value: string): boolean => {
    if (name === 'size' || name === 'prompt') {
      return value.trim() === '';
    }
    return false;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formErrors: FormErrors = {
      size: validateField('size', formData.size),
      prompt: validateField('prompt', formData.prompt),
    };
    setErrors(formErrors);
    const isValid = Object.values(formErrors).every((error) => !error);

    if (isValid && !questionActiveStatus[activeView]) {
      addImageMessage(activeView, formData.prompt);
      setGenerateImageMessage(formData);
    } else {
      console.log('Validation Failed');
    }
  };

  const handleSliderChange = (_event: Event, value: number | number[]) => {
    setFormData({ ...formData, n: value as number });
  };

  const isFormValid = Object.values(errors).every((error) => !error) && formData.size !== '';

  const sizes = selectedModel.model === 'dall-e-3' ? v3sizes : v2sizes;

  return (
    <form className="form" onSubmit={handleSubmit}>
      <label className="form-label">{t('chatGpt.generateImage.label')}</label>
      <div className="model-selector-container gpt-model-selector">
        <ModelSelector models={models} selectedModel={selectedModel} setSelectedModel={setSelectedModel} />
      </div>
      <Tooltip title={t('chatGpt.generateImage.prompt')} placement="top-start" enterDelay={500} leaveDelay={200}>
        <TextField
          label={t('chatGpt.generateImage.prompt')}
          name="prompt"
          type="text"
          variant="outlined"
          value={formData.prompt}
          onChange={handleInputChange}
          fullWidth
          minRows={1}
          maxRows={30}
          multiline
          className="form-input generate-image-input"
          InputProps={{ style: { maxHeight: '40vh', overflow: 'scroll' } }}
        />
      </Tooltip>
      <FormControl
        fullWidth
        sx={{
          '.MuiInputBase-root': {
            backgroundColor: '#f9f9f9',
            border: '1px solid #dedede',
            borderRadius: '12px',
            '& .MuiSelect-select': {
              borderRadius: '12px',
            },
            '&:hover fieldset': {
              borderColor: '#39B0BB',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#39B0BB',
            },
          },
          marginBottom: '20px',
        }}
      >
        <InputLabel>{t('chatGpt.generateImage.size')}</InputLabel>
        <Select value={formData.size} onChange={handleDropdownChange} name="size">
          {sizes.map((resolution, index) => (
            <MenuItem key={index} value={resolution}>
              {resolution}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedModel.model !== 'dall-e-3' ? (
        <>
          <div className="difficulty-label">
            <InputLabel style={{ fontWeight: 'lighter', color: 'black', fontSize: '18px' }} htmlFor="grade">
              {t('chatGpt.generateImage.numberOfImages')}
            </InputLabel>
          </div>
          <div className="difficulty-slider">
            <Slider
              value={formData.n}
              onChange={handleSliderChange}
              aria-labelledby="difficulty-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={2}
            />
          </div>
        </>
      ) : (
        <>
          <div className="generate-image-style">
            <FormControl
              fullWidth
              sx={{
                '.MuiInputBase-root': {
                  backgroundColor: '#f9f9f9',
                  border: '1px solid #dedede',
                  borderRadius: '12px',
                  '& .MuiSelect-select': {
                    borderRadius: '12px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#39B0BB',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#39B0BB',
                  },
                },
                marginBottom: '20px',
              }}
            >
              <InputLabel>{t('chatGpt.generateImage.style')}</InputLabel>
              <Select value={formData.style} onChange={handleDropdownChange} name="style">
                {['vivid', 'natural'].map((style, index) => (
                  <MenuItem key={index} value={style}>
                    {style.toLocaleUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isFormValid}
        style={{ backgroundColor: isFormValid ? '#39B0BB' : '' }}
      >
        {t('chatGpt.generateImage.submit')}
      </Button>
    </form>
  );
};
