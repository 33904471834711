import create from 'zustand';
import {
  AIView,
  ChatState,
  GenerateImageMessage,
  GenerateImageMessageInner,
  ImageChatMessage,
  ImageMessageBuffer,
  ImageResponse,
  QAItem,
} from '../models/AIChatTypes';
import { t } from 'i18next';
import { teacherModels, studentModels } from '../components/AI/GPTModelSelector';
import { v4 as uuidv4 } from 'uuid';
import { getAuthenticationStringAttachment } from 'utils/AuthenticationHelpers';

export const useChatStore = create<ChatState>((set) => ({
  activeView: AIView.CHAT,
  messages: {},
  connectionStatus: 'disconnected',
  prompt: null,
  questionActiveStatus: {},
  cancelViewPrompt: null,
  selectedLanguage: undefined,
  selectedGPTModel: studentModels[0],
  teacherModels: teacherModels,
  studentModels: studentModels,
  selectedFrequencyPenalty: 0,
  selectedPresencePenalty: 0,
  selectedTemperature: 1,
  generateImageMessage: null,
  imageResponseBuffer: new ImageMessageBuffer(),
  imageMessages: {},

  addImageMessage: (view: string, prompt: string) => {
    set((state) => {
      if (!Array.isArray(state.imageMessages[view])) {
        state.imageMessages[view] = [];
      }

      const message: ImageChatMessage = {
        id: uuidv4(),
        prompt,
        processing: true,
        response: null,
      };

      const imageMessages = { ...state.imageMessages };
      imageMessages[view] = [...imageMessages[view], message];
      return {
        ...state,
        imageMessages,
      };
    });
  },

  /**
   * Adds an image response to the image messages in the specified view.
   *
   * @param {string} view - The view in which the image response should be added.
   * @param {string} response - The JSON string representation of the image response.
   * @return {void}
   */
  addImageResponse: (view: string, response: string) => {
    set((state) => {
      if (!Array.isArray(state.imageMessages[view])) {
        state.imageMessages[view] = [];
      }

      const parsedResponse: ImageResponse = JSON.parse(response);
      console.log(parsedResponse);

      for (let idx = 0; idx < state.imageMessages[view].length; idx++) {
        const item = state.imageMessages[view][idx];
        if (item.processing) {
          item.response = parsedResponse;
          item.processing = false;
          break;
        }
      }
      return {
        ...state,
      };
    });
  },

  setGenerateImageMessage: (innerMessage: GenerateImageMessageInner) =>
    set((state) => {
      const questionID = uuidv4();
      const authString = getAuthenticationStringAttachment() || '';
      const viewID = AIView.GENERATE_IMAGE;
      const generateImageMessage: GenerateImageMessage = {
        questionID,
        authString,
        viewID,
        innerRequest: innerMessage,
      };
      console.log(generateImageMessage);

      return {
        ...state,
        generateImageMessage,
      };
    }),

  setQuestionActiveStatus: (view, status) =>
    set((state) => {
      const messages = { ...state.messages };
      const viewMessages = messages[view];
      if (viewMessages && viewMessages.length > 0) {
        const lastItem = viewMessages[viewMessages.length - 1];
        if (lastItem) {
          if (status === false) {
            lastItem.questionProcessed = true;
          }
        }
      }
      return {
        ...state,
        messages,
        questionActiveStatus: {
          ...state.questionActiveStatus,
          [view]: status,
        },
      };
    }),
  setPrompt: (prompt) => set({ prompt }),
  addMessage: (view, qaItem) =>
    set((state) => ({
      messages: {
        ...state.messages,
        [view]: [...(state.messages[view] || []), qaItem],
      },
    })),
  setConnectionStatus: (status) => set({ connectionStatus: status }),
  addResponse: (view, response) => {
    set((state) => {
      const parsedResponse = JSON.parse(response);

      let viewMessages = state.messages[view] || [];

      if (viewMessages.length === 0) {
        const newQAItem: QAItem = {
          id: parsedResponse.id,
          question: '',
          answer: parsedResponse.choices?.[0]?.delta?.content || '',
          ignoreRenderInChat: false,
        };

        viewMessages = [newQAItem];
      } else {
        const lastMessage = viewMessages[viewMessages.length - 1];
        if (lastMessage) {
          if (lastMessage.answer == null) {
            lastMessage.answer = parsedResponse.choices?.[0]?.delta?.content || '';
          } else {
            lastMessage.answer += parsedResponse.choices?.[0]?.delta?.content || '';
          }
        }
      }

      return {
        ...state,
        messages: {
          ...state.messages,
          [view]: viewMessages,
        },
      };
    });
  },
  setCancelViewPrompt: (id) =>
    set((state) => {
      return {
        ...state,
        cancelViewPrompt: id,
      };
    }),
  getViewLatestQuestionId: (view) => {
    const viewMessages: QAItem[] = useChatStore.getState().messages[view];
    if (!viewMessages) return undefined;
    const lastMessage = viewMessages[viewMessages?.length - 1];
    if (lastMessage) {
      return lastMessage.id;
    }
    return undefined;
  },
  setSelectedLanguage: (language) => set({ selectedLanguage: language }),
  markLastQuestionProcessed: (view) => {
    set((state) => {
      const viewMessages = state.messages[view];
      if (viewMessages && viewMessages.length > 0) {
        const lastItem = viewMessages[viewMessages.length - 1];
        if (lastItem) {
          lastItem.questionProcessed = true;
        }
      }
      return {
        ...state,
        messages: {
          ...state.messages,
          [view]: viewMessages,
        },
      };
    });
  },
  clearEmptyMessageIfExist(view) {
    set((state) => {
      const filteredQuestionsAndAnswers = state.messages[view].map((item) => {
        if (item.answer === null || item.answer === undefined || item.answer === '') {
          item.answer = t('chatGpt.cancelledPrompt');
        }
        return item;
      });

      return {
        messages: {
          ...state.messages,
          [view]: [...filteredQuestionsAndAnswers],
        },
      };
    });
  },
  cancelQuestion: (view) => {
    set((state) => {
      state.setQuestionActiveStatus(view, false);
      state.setCancelViewPrompt(view);
      state.markLastQuestionProcessed(view);
      state.clearEmptyMessageIfExist(view);
      return state;
    });
  },
  setSelectedGPTModel: (model) => set((state) => ({ ...state, selectedGPTModel: model })),
  setSelectedFrequencyPenalty: (penalty) => set((state) => ({ ...state, selectedFrequencyPenalty: penalty })),
  setSelectedTemperature: (temperature) => set((state) => ({ ...state, selectedTemperature: temperature })),
  setSelectedPresencePenalty: (penalty) => set((state) => ({ ...state, selectedPresencePenalty: penalty })),
  setActiveView: (view) => set({ activeView: view }),
  currentActiveView: () => {
    let res: string = AIView.CHAT;

    set((state) => {
      res = state.activeView;

      return state;
    });

    return res;
  },
  setLastImageMessageStatus: (status) => {
    set((state) => {
      const length = state.imageMessages[AIView.GENERATE_IMAGE].length;

      const item = state.imageMessages[AIView.GENERATE_IMAGE][length - 1];

      item.processing = status;

      return state;
    });
  },
}));
